import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY, UserModel } from "../../../models/user-model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-looking-for',
  templateUrl: './looking-for.component.html',
  styleUrls: ['./looking-for.component.scss']
})
export class LookingForComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string[] | string}>();
  genderForm: FormGroup;
  sliderLowValue: number = 25;
  sliderHighValue: number = 40;
  ngxSliderOption: Options = {
    floor: 18,
    ceil: 80,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.Floor:
          return 'Age';
        default:
          return '' + value;
      }
    }
  };
  userState!: UserModel;

  genders: {value: string, text: string}[] = [
    { value: 'female', text: 'Female' },
    { value: 'male', text: 'Male' }
  ];
  constructor(
    private _renderer: Renderer2,
    private _fb: FormBuilder,
    private _storageService: StorageService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {
    this.genderForm = this._fb.group({
      gender: 'female'
    });

    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(): void {
  }

  continue(event: any): void {
    const value: string = this.genderForm.value.gender;

    if (!value || !this.sliderLowValue || !this.sliderHighValue) return;

    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'lookingFor' , data: value});
    this.continueEvent.emit({propName: 'age' , data: [this.sliderLowValue.toString(), this.sliderHighValue.toString()]});

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));

    const uuid: string = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      age: [this.sliderLowValue.toString(), this.sliderHighValue.toString()],
      lookingFor: value
    }).subscribe();
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('a_who_attract_you_page_continue_clicked');
  }
}
