<!--<router-outlet></router-outlet>-->

<!--<app-welcome [hidden]="!(currentUrl === '/')"></app-welcome>-->

<!--<app-questions-flow [hidden]="!routes.QUESTIONS_FLOW" [initFire]="routes.QUESTIONS_FLOW"></app-questions-flow>-->

<!--<app-email [hidden]="!routes.EMAIL"></app-email>-->

<!--<app-find-ideal [hidden]="!routes.FIND_IDEAL"></app-find-ideal>-->

<!--<app-checkout [hidden]="!routes.CHECKOUT" [initFire]="routes.CHECKOUT"></app-checkout>-->

<!--<app-checkout-error [hidden]="!routes.CHECKOUT_ERROR"></app-checkout-error>-->

<!--<app-checkout-success [hidden]="!routes.CHECKOUT_SUCCESS"></app-checkout-success>-->




<app-welcome *ngIf="currentUrl === '/'"></app-welcome>

<app-questions-flow *ngIf="routes.QUESTIONS_FLOW" [initFire]="routes.QUESTIONS_FLOW"></app-questions-flow>

<app-email *ngIf="routes.EMAIL"></app-email>

<app-find-ideal *ngIf="routes.FIND_IDEAL"></app-find-ideal>

<app-checkout *ngIf="routes.CHECKOUT" [initFire]="routes.CHECKOUT"></app-checkout>

<app-checkout-error *ngIf="routes.CHECKOUT_ERROR"></app-checkout-error>

<app-checkout-success *ngIf="routes.CHECKOUT_SUCCESS"></app-checkout-success>
