<section class="container text-center">
  <div class="checkout-header">
    <img src="../../../assets/images/elizium-logo-dark.svg" alt="">
  </div>

  <img src="../../../assets/images/icon-success.svg" class="circle-img" alt="">

  <h3>
    Your account is activated!
  </h3>

  <p class="info-big description-one">
    Here is your voucher number:
  </p>

  <p class="voucher-wrap">
    {{voucher}}
  </p>

  <p class="info-big description-two">
    Use the following button to download <br/>Elizium and <b>activate</b> your account:
  </p>

  <button class="button button-primary size-normal" id="btn-install-checkout-success"
          (click)="navigateToShop($event)"><i class="icon icon-install"></i> Install</button>

  <p class="info">
    Want to deactivate your subscription?
    <br>You can always <a class="link" id="link-support-checkout-success"
                          href="mailto:support@elizium.co?subject=Subscription%20Cancellation&body=Hi,%0A%0AI%20recently%20purchased%20your%20subscription%20and%20I%20want%20to%20cancel%20it.">reach us</a> to cancel it
  </p>

</section>
