import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRouter } from "./app.routes";
import { AuthGuardService } from "./services/auth-guard.service";
import { StorageService } from "./services/storage.service";
import { WelcomeModule } from "./pages/welcome/welcome.module";
import { QuestionsFlowModule } from "./pages/questions-flow/questions-flow.module";
import { EmailModule } from "./pages/email/email.module";
import { CheckoutModule } from "./pages/checkout/checkout.module";
import { CheckoutErrorModule } from "./pages/checkout-error/checkout-error.module";
import { FindIdealModule } from "./pages/find-ideal/find-ideal.module";
import { CountdownModule } from "ngx-countdown";
import { ApiService } from "./services/api.service";
import { UserService } from "./services/user.service";
import { AppsflyerService } from "./services/appsflyer.service";
import { CheckoutSuccessModule } from "./pages/checkout-success/checkout-success.module";
import { NgxStripeModule } from "ngx-stripe";
import { GeoLocationService } from "./services/geo-location";

const appInitializerFn = (storageService: StorageService) => {
  return async () => {
    await storageService.init();
    return true;
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRouter,
    WelcomeModule,
    QuestionsFlowModule,
    EmailModule,
    FindIdealModule,
    CheckoutModule,
    CheckoutErrorModule,
    CheckoutSuccessModule,
    CountdownModule,
    NgxStripeModule.forRoot('pk_test_51PbLd82LNcpOVYa02L9Syub9t7o7mD5XVdjGTmgSIfG7hVdYaZJJX3q9M9EeWaSWJzGtnKCKUoic0jUibunE8WSQ00PhCz1VYU') // pk_live_51PbLd82LNcpOVYa0iuAPwJDojBvBoqqtpZKspvOa8234GF6DSxubgdXBOTW1M1wn8bPdzIwqAAplUSbwWAJhjvgH00Nec8mRqc
  ],
  providers: [
    AuthGuardService,
    StorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [StorageService],
      multi: true
    },
    ApiService,
    UserService,
    AppsflyerService,
    GeoLocationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
