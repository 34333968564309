<app-header [showBackButton]="true" (goBackEvent)="goBack()" [darkMode]="true"></app-header>

<section class="container">
  <app-user-info [userStateData]="userState"></app-user-info>

  <div class="ideal-partner text-center">
    <h1>
      We found over 745 partners in {{userState && userState.questions ? userState.questions.location.data : ''}}
      <span *ngIf="userState && userState.questions && userState.questions.datingGoal.data !== DATING_GOAL_KEYS.NOT_SURE_YET">
      , who are looking for a {{(userState && userState.questions) ? (userState.questions.datingGoal.data | datingGoal) : ''}}
      </span>
    </h1>
    <div class="img-wrap">
      <img src="../../../assets/images/people.png" alt="">
    </div>

    <div class="plan-wrap">
      <div class="current-plan">
        <div class="sale-block">
          <p class="price-full">${{activePlan.fullPricePerWeek}} / week</p>
          <p class="discount-percentage">-{{activePlan.discount}}% Discount</p>
        </div>
        <div class="price-block">
          <h5 class="price-discount">${{activePlan.discountPricePerWeek}} / week</h5>
          <div class="price-weekly">Only ${{activePlan.discountPrice}} / {{activePlan.name}}</div>
        </div>
        <div class="show-more-btn">
          <p (click)="showedPlans = !showedPlans; setAmplitudeOnAllPlansClickEvent()">View other plans</p>
        </div>
        <div class="plans-list" *ngIf="showedPlans">
          <p class="pick-plan">Pick your plan</p>
          <ul>
            <li *ngFor="let plan of plans" [hidden]="plan.id === activePlan.id" (click)="setActivePlan(plan.id)">
              <div class="name-price">
                <p class="name">{{plan.name}} <span *ngIf="plan.id === 'monthly'">Popular</span></p>
              </div>
              <div class="week-price">${{plan.discountPricePerWeek}} / week</div>
            </li>
          </ul>
        </div>

        <div class="button-wrap">
          <button class="button button-primary size-normal" #buttonTryTrial (click)="startTrial($event)"
                  id="btn-continue-find-ideal">Get laid with them!</button>
        </div>
      </div>
    </div>
  </div>

  <div class="cancel-wrap text-center">
    <p><i class="icon icon-shield-small"></i>Cancel anytime. Secured with App Store.</p>
  </div>

  <div class="stars-feedback-wrap text-center">
    <div class="stars">
      <i class="icon icon-super-gold-star-big"></i>
      <i class="icon icon-super-gold-star-big"></i>
      <i class="icon icon-super-gold-star-big"></i>
      <i class="icon icon-super-gold-star-big"></i>
      <i class="icon icon-super-gold-star-big"></i>
    </div>
    <p>Enjoyed by 100K+ users</p>
  </div>

  <div class="guarantee-wrap text-center">
    <div class="icon-wrap">
      <i class="icon icon-money"></i>
    </div>
    <h3>100% Money-back guarantee</h3>
    <p>
      The main goal of Elizium is to create the best for you. Therefore, we carefully designed our product.
      If you don’t meet anyone after using the app for at least a week,
      we are ready to make a complete refund within <strong>14 days.</strong>
    </p>
  </div>

  <div class="stories-wrap">
    <h2 class="text-center">Here are few stories<br/>from Elizium users</h2>

    <ul class="stories-list">
      <li>
        <div class="info">
          <div class="avatar-wrap">
            <img src="../../../assets/images/review-emma.svg" alt="">
          </div>
          <div class="name-review">
            <p>Emma Clarke</p>
            <ul class="stars-list">
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
            </ul>
          </div>
        </div>
        <div class="text">
          <p>
            Found my dream match on Elizium the same day I signed up—it was my neighbor!
            I've never been so satisfied.
            Our fantasies perfectly aligned, but now our other neighbors hate us.
          </p>
        </div>
      </li>

      <li>
        <div class="info">
          <div class="avatar-wrap">
            <img src="../../../assets/images/review-sophia.svg" alt="">
          </div>
          <div class="name-review">
            <p>Sophia Nguyen</p>
            <ul class="stars-list">
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
            </ul>
          </div>
        </div>
        <div class="text">
          <p>
            The idea of Elizium is so simple, yet it works so much better than other apps! This app made my wildest dreams come true.
          </p>
        </div>
      </li>

      <li>
        <div class="info">
          <div class="avatar-wrap">
            <img src="../../../assets/images/review-ethan.svg" alt="">
          </div>
          <div class="name-review">
            <p>Ethan Brooks</p>
            <ul class="stars-list">
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
              <li><i class="icon icon-gold-star-small"></i></li>
            </ul>
          </div>
        </div>
        <div class="text">
          <p>
            Never thought I'd find someone who matches my fantasies until Elizium. Even though I was initially looking
            for sex, I ended up in a perfect relationship.
          </p>
        </div>
      </li>

    </ul>
  </div>

  <div class="featured-wrap text-center">
    <p>As featured in</p>
    <img src="../../../assets/images/companies.png" alt="">
  </div>

  <div class="support-wrap text-center">
    <p class="title">Questions? We’re here to help.</p>
    <a class="link" href="mailto:support@elizium.co" id="link-support-find-ideal"><i class="icon icon-support"></i><span>Customer Support</span></a>
  </div>
</section>

<div class="ideal-footer" [ngClass]="{'visible': !isButtonTryTrialIntoView}">
  <div class="container">
    <div class="counter">
      <countdown #cd [config]="countDownConfig"></countdown>
    </div>
    <button class="button button-primary size-normal" (click)="startTrial($event, true)"
            id="btn-lets-go-find-ideal">Let’s Go</button>
  </div>
</div>

