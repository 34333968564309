import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { CreateUserModel, TIME_DELAY, UserModel } from "../../../models/user-model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.scss']
})
export class GenderComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string}>();
  genderForm: FormGroup;
  userState!: UserModel;
  genders: {value: string, text: string}[] = [
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' }
  ];

  constructor(
    private _renderer: Renderer2,
    private _fb: FormBuilder,
    private _storageService: StorageService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {
    this.genderForm = this._fb.group({
      gender: ''
    });

    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(): void {
    this.genderForm.controls['gender'].valueChanges.subscribe(value => {
      setTimeout(() => {
        this.continue();
      }, (TIME_DELAY - 5));
    });
  }

  continue(): void {
    const value: string = this.genderForm.value.gender;
    if (!value) return;

    this.continueEvent.emit({propName: 'gender' , data: value});

    this.setAmplitudeOnBtnClickEvent();

    const requestData: CreateUserModel = {
      gender: this.userState.questions.gender ? this.userState.questions.gender.data : 'error happen',
      project_type: 'REAL_PAYMENT'
    };

    if (this.userState.utmTags) requestData.utm_tags = this.userState.utmTags;

    this._userService.createUserData(requestData).subscribe((response) => {
      this.userState.uuid = response.id;
      this._storageService.setUserState(this.userState);
    }, error => {
      console.error('GenderComponent#createUserData error: ', error);
    });
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('a_your_gender_page_continue_clicked');
  }
}
