<section class="container">
  <div class="checkout-header text-center">
    <img src="../../../assets/images/elizium-logo-dark.svg" alt="">
    <p>
      Revolutionizing casual dating
      <br>Enjoyed by over 500,000 people
    </p>
    <ul class="stars-list">
      <li><i class="icon icon-gold-star-big"></i></li>
      <li><i class="icon icon-gold-star-big"></i></li>
      <li><i class="icon icon-gold-star-big"></i></li>
      <li><i class="icon icon-gold-star-big"></i></li>
      <li><i class="icon icon-gold-star-big"></i></li>
    </ul>
  </div>

  <div class="personalized-offer-wrap">
    <p>Personalized offer reserved</p>
    <div class="count">
      <countdown #cd [config]="countDownConfig"></countdown>
    </div>
  </div>

  <div class="checkout-body">
    <h2>Start your subscription</h2>
    <div class="total">
      <p class="total-text">Total today</p>
      <p class="total-price">{{userState ? userState.price : ''}}</p>
    </div>
    <div class="sale">
      <i class="icon icon-sale"></i>
      <p>Code PARA23 applied!</p>
    </div>
    <div class="price-description">
      <p>You will be charged <span class="old-price">{{userOldPrice}}</span>&nbsp;&nbsp;<strong>{{userState ? userState.price : ''}}
        for your subscription.</strong>
      </p>
    </div>
    <div class="benefits">
      <div class="item">
        <i class="icon icon-calendar"></i>
        <p>No commitment.<br/>Cancel anytime.</p>
      </div>
      <div class="item">
        <i class="icon icon-shield-check"></i>
        <p>30-Day Money-Back<br/>Guarantee</p>
      </div>
    </div>
  </div>
</section>

<div class="checkout-footer text-center">
  <div class="container">
    <h4>Total due today: {{userState ? userState.price : ''}}</h4>
    <button  class="button size-normal button-card" id="btn-open-checkout-form"
             (click)="showCreditCardForm = !showCreditCardForm; setAmplitudeOnBtnClickEvent()">
      <i class="icon icon-card"></i>
      Debit / Credit Card
    </button>

    <div class="cell example example4" id="example-4">

      <div [hidden]="!showCreditCardForm" class="form-popup">

        <div class="popup-wrap">
          <span class="icon close" (click)="showCreditCardForm = !showCreditCardForm">x</span>
          <h4>Credit / Debit Card</h4>

          <div class="benefits">
            <div class="item">
              <i class="icon icon-calendar"></i>
              <p>No commitment.<br/>Cancel anytime.</p>
            </div>
            <div class="item">
              <i class="icon icon-shield-check"></i>
              <p>30-Day Money-Back<br/>Guarantee</p>
            </div>
          </div>

          <form (submit)="onSubmit($event)" class="mt-5">
            <!-- Name on Card -->
            <div class="field-wrap">
              <input id="card-name" type="text" class="input-field" placeholder="John Doe" required />
            </div>

            <!-- Card Number -->
            <div class="field-wrap">
              <div id="card-number" class="input-field input"></div>
            </div>

            <!-- Expiry and CVV -->
            <div class="field-wrap row">
              <div class="col">
                <div id="card-expiry" class="input-field input"></div>
              </div>
              <div class="col">
                <div id="card-cvc" class="input-field input"></div>
              </div>
            </div>

            <button type="submit" class="button size-normal button-card submit-button mt-6"
                    id="btn-credit-card-checkout">Pay</button>

            <div class="error" role="alert">
              <span class="message"></span>
            </div>

            <div class="success">
              <div class="icon">
                <!-- Success icon -->
              </div>
              <h3 class="title">Payment successful</h3>
              <p class="message">
                <span>Your payment was processed successfully.</span>
              </p>
            </div>
          </form>

        </div>
      </div>


      <div class="or-wrap">
        <span>or</span>
      </div>


      <button class="button size-normal button-apple-pay" id="btn-apple-pay-checkout" style="display: none;">
        <i class="icon icon-apple-pay"></i>
        Apple Pay
      </button>
      <button class="button size-normal button-google-pay" id="btn-google-pay-checkout" style="display: none;">
        <i class="icon icon-google-pay"></i>
        Google Pay
      </button>

    </div>
  </div>
</div>
