<h1 class="question">
  What is your <br/>gender?
</h1>

<div class="gender-container">
  <form [formGroup]="genderForm">
    <div class="gender-box" *ngFor="let gender of genders; let i = index;">
      <input type="radio" id="{{gender.value}}" formControlName="gender" [value]="gender.value">
      <label for="{{gender.value}}" class="gender-button"><span class="icon-gen icon-gen-{{i + 1}}"></span>{{gender.text}}</label>
    </div>
  </form>
</div>
