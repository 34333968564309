<app-header [darkMode]="true"></app-header>

<section class="container text-center">
  <div class="img-wrap">
    <img src="../../../assets/images/img-main.png" alt="">
  </div>
  <h1>Find an ideal sex partner
    <br/>sharing your desires</h1>
  <p class="description">Our community count over 750,000 attractive members, with verified profiles and 100% private chats.</p>
  <button  (click)="start($event)" class="button button-primary size-normal" id="btn-get-started-home">
    Get started
  </button>
</section>

